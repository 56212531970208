import type {ApolloError} from '@apollo/client';
import type {GraphQLFormattedError} from 'graphql';

const DEFAULT_PATHS = [
  'metaobjectDefinition',
  'metafieldDefinition',
  'image',
  'discoveryConfiguration',
];

export function isExpectedAccessDeniedError(
  error: GraphQLFormattedError,
  paths: string[] = DEFAULT_PATHS,
) {
  return (
    error.extensions?.code === 'ACCESS_DENIED' &&
    paths.some((path) => error.path?.includes(path))
  );
}

export function isErrorExpected(
  error: ApolloError,
  paths: string[] = DEFAULT_PATHS,
) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors.every((error) =>
      isExpectedAccessDeniedError(error, paths),
    ) &&
    !error.networkError
  );
}
